.ca-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MuiPaper-root > .MuiBox-root > :nth-child(1),
.MuiPaper-root > .MuiBox-root > :nth-child(3) > button:nth-child(1),
.MuiPaper-root > .MuiBox-root > :nth-child(3) > button:nth-child(3),
.MuiPaper-root > .MuiBox-root > :nth-child(6)
{
  display: none !important;
}

.MuiPaper-root > .MuiBox-root > :nth-child(2)
{
  min-width: 210px;
}